import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMProductHydrationDropsViewTrigger = () => void;

export const useGTMProductHydrationDropsViewEffect =
  (): GTMProductHydrationDropsViewTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const gtmProductHydrationDropsView = () => {
      setTriggered(true);
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_PRODUCT_HYDRATION_DROPS_VIEW,
          })
        );
      }
    }, [hasTriggered, setTriggered, hasSent, setSent, dispatch]);

    return gtmProductHydrationDropsView;
  };
