import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMProductHomeStartTrigger = () => void;

export const useGTMProductHomeStartEffect = (): GTMProductHomeStartTrigger => {
  const dispatch = useDispatch();

  const [hasTriggered, setTriggered] = useState(false);
  const [hasSent, setSent] = useState(false);

  const gtmProductHomeStart = () => {
    setTriggered(true);
  };

  useEffect(() => {
    if (hasTriggered && !hasSent) {
      setTriggered(false);
      setSent(true);

      dispatch(
        gtmPageSlice.actions.update({
          event: GTMPageEvent.WALDO_PRODUCT_HOME_START,
        })
      );
    }
  }, [hasTriggered, setTriggered, hasSent, setSent, dispatch]);

  return gtmProductHomeStart;
};
