import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMProductVitaminLensesAddTrigger = (cart_id: number) => void;

export const useGTMProductVitaminLensesAddEffect =
  (): GTMProductVitaminLensesAddTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataProductVitaminLensesAdd, setProductVitaminLensesAdd] = useState<{
      cart_id: number;
    } | null>(null);

    const gtmProductVitaminLensesAdd = (cart_id: number) => {
      if (dataProductVitaminLensesAdd?.cart_id !== cart_id) {
        setProductVitaminLensesAdd({ cart_id });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_PRODUCT_VITAMIN_LENSES_ADD,
            ...dataProductVitaminLensesAdd,
          })
        );
      }
    }, [
      dataProductVitaminLensesAdd,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmProductVitaminLensesAdd;
  };
