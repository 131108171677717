import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gtmPageSlice } from '../../../../redux';
import { Currency } from '@hiwaldo/sdk';
import { RevrollBeginCheckoutProductsInterface } from '../../../../interfaces/page/revroll';
import { RevrollEvent } from '../../../../enums/revroll-event.enum';
import { Cart } from '@hiwaldo/sdk/interfaces';
import { calculateGtmCartItems } from '../../../../utils';

export type RevrollBeginCheckoutTrigger = (
  cart: Cart,
  cart_id?: number
) => void;

export const useRevrollBeginCheckoutEffect =
  (): RevrollBeginCheckoutTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);
    const [currentCartId, setCurrentCartId] = useState(
      <{ cart_id: number } | null>null
    );

    const [dataRevrollBeginCheckout, setRevrollBeginCheckout] = useState<{
      ecommerce: {
        currencyCode: Currency;
        checkout: {
          actionField: any;
          products: RevrollBeginCheckoutProductsInterface[];
        };
      };
    } | null>(null);

    const revrollBeginCheckout = (cart: Cart) => {
      if (currentCartId?.cart_id !== cart.id) {
        const cartProducts = calculateGtmCartItems(cart);

        const products: RevrollBeginCheckoutProductsInterface[] = [] as any;

        for (let i = 0; i < cartProducts.items.length; i++) {
          const items = {} as RevrollBeginCheckoutProductsInterface;
          items.name = cartProducts.items[i].item_name;
          items.id = cartProducts.items[i].item_id;
          items.sku = cartProducts.items[i].sku || '';
          items.variant_id = cartProducts.items[i].item_variant || '';
          items.price = cartProducts.items?.[i]?.price || 0;
          items.brand = 'Waldo Store';
          items.category = '';
          items.image = '';
          items.quantity = cartProducts.items?.[i]?.quantity || 0;

          products.push(items);
        }

        setRevrollBeginCheckout({
          ecommerce: {
            currencyCode: Currency.USD,
            checkout: {
              actionField: { step: 1 },
              products,
            },
          },
        });
        setCurrentCartId({ cart_id: cart.id });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);
        dispatch(
          gtmPageSlice.actions.revrollUpdate({
            event: RevrollEvent.REVROLL_BEGIN_CHECKOUT,
            rr_cart_id: currentCartId?.cart_id,
            ...dataRevrollBeginCheckout,
          })
        );
      }
    }, [
      dataRevrollBeginCheckout,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return revrollBeginCheckout;
  };
