import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMProductGiftCardViewTrigger = () => void;

export const useGTMProductGiftCardViewEffect =
  (): GTMProductGiftCardViewTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const gtmProductGiftCardView = () => {
      setTriggered(true);
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_PRODUCT_GIFT_CARD_VIEW,
          })
        );
      }
    }, [hasTriggered, setTriggered, hasSent, setSent, dispatch]);

    return gtmProductGiftCardView;
  };
