import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gtmPageSlice } from '../../../../redux';
import { GTMPageEvent } from '../../../../enums';

export type GTMAccountViewTrigger = (
  customer_id: number,
  order_id?: number
) => void;

export const useGTMAccountViewEffect = (): GTMAccountViewTrigger => {
  const dispatch = useDispatch();

  const [hasTriggered, setTriggered] = useState(false);
  const [hasSent, setSent] = useState(false);

  const [dataAccountView, setAccountView] = useState<{
    customer_id: number;
    order_id?: number;
  } | null>(null);

  const gtmAccountView = (customer_id: number, order_id?: number) => {
    if (!dataAccountView?.customer_id && customer_id) {
      setAccountView({ customer_id, order_id });
      setTriggered(true);
    }
  };

  useEffect(() => {
    if (hasTriggered && !hasSent) {
      setTriggered(false);
      setSent(true);

      dispatch(
        gtmPageSlice.actions.update({
          event: GTMPageEvent.WALDO_ACCOUNT_VIEW,
          ...dataAccountView,
        })
      );
    }
  }, [dataAccountView, hasTriggered, setTriggered, hasSent, setSent, dispatch]);

  return gtmAccountView;
};
