import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMCheckoutPaymentViewTrigger = (cart_id: number) => void;

export const useGTMCheckoutPaymentViewEffect =
  (): GTMCheckoutPaymentViewTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataCheckoutPaymentView, setCheckoutPaymentView] = useState<{
      cart_id: number;
    } | null>(null);

    const gtmCheckoutPaymentView = (cart_id: number) => {
      if (!dataCheckoutPaymentView?.cart_id && cart_id) {
        setCheckoutPaymentView({ cart_id });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_CHECKOUT_PAYMENT_VIEW,
            ...dataCheckoutPaymentView,
          })
        );
      }
    }, [
      dataCheckoutPaymentView,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmCheckoutPaymentView;
  };
