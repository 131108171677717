import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMProductOriginalLensesAddTrigger = (cart_id: number) => void;

export const useGTMProductOriginalLensesAddEffect =
  (): GTMProductOriginalLensesAddTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataProductOriginalLensesAdd, setProductOriginalLensesAdd] =
      useState<{
        cart_id: number;
      } | null>(null);

    const gtmProductOriginalLensesAdd = (cart_id: number) => {
      if (dataProductOriginalLensesAdd?.cart_id !== cart_id) {
        setProductOriginalLensesAdd({ cart_id });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_PRODUCT_ORIGINAL_LENSES_ADD,
            ...dataProductOriginalLensesAdd,
          })
        );
      }
    }, [
      dataProductOriginalLensesAdd,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmProductOriginalLensesAdd;
  };
