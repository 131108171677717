import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gtmPageSlice } from '../../../../redux';
import { GTMPageEvent } from '../../../../enums';

export type GTMCheckoutLoginTrigger = (customer_id: number) => void;

export const useGTMCheckoutLoginEffect = (): GTMCheckoutLoginTrigger => {
  const dispatch = useDispatch();

  const [hasTriggered, setTriggered] = useState(false);
  const [hasSent, setSent] = useState(false);

  const [dataCheckoutLogin, setCheckoutLogin] = useState<{
    customer_id: number;
  } | null>(null);

  const gtmCheckoutLogin = (customer_id: number) => {
    if (!dataCheckoutLogin?.customer_id && customer_id) {
      setCheckoutLogin({ customer_id });
      setTriggered(true);
    }
  };

  useEffect(() => {
    if (hasTriggered && !hasSent) {
      setTriggered(false);
      setSent(true);

      dispatch(
        gtmPageSlice.actions.update({
          event: GTMPageEvent.WALDO_CHECKOUT_LOGIN,
          ...dataCheckoutLogin,
        })
      );
    }
  }, [
    dataCheckoutLogin,
    hasTriggered,
    setTriggered,
    hasSent,
    setSent,
    dispatch,
  ]);

  return gtmCheckoutLogin;
};
