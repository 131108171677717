import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMCheckoutShippingViewTrigger = (cart_id: number) => void;

export const useGTMCheckoutShippingViewEffect =
  (): GTMCheckoutShippingViewTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataCheckoutShippingView, setCheckoutShippingView] = useState<{
      cart_id: number;
    } | null>(null);

    const gtmCheckoutShippingView = (cart_id: number) => {
      if (!dataCheckoutShippingView?.cart_id && cart_id) {
        setCheckoutShippingView({ cart_id });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_CHECKOUT_SHIPPING_VIEW,
            ...dataCheckoutShippingView,
          })
        );
      }
    }, [
      dataCheckoutShippingView,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmCheckoutShippingView;
  };
