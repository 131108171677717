import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMTrialFunnelEyePowerPageViewTrigger = (cart_id?: number) => void;

export const useGTMTrialFunnelEyePowerPageViewEffect =
  (): GTMTrialFunnelEyePowerPageViewTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataTrialFunnelEyePowerPageView, setTrialFunnelEyePowerPageView] =
      useState<{
        cart_id?: number;
      } | null>(null);

    const gtmTrialFunnelEyePowerPageView = (cart_id?: number) => {
      if (dataTrialFunnelEyePowerPageView?.cart_id !== cart_id) {
        setTrialFunnelEyePowerPageView({ cart_id });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_TRIAL_FUNNEL_EYE_POWER_PAGE_VIEW,
            ...dataTrialFunnelEyePowerPageView,
          })
        );
      }
    }, [
      dataTrialFunnelEyePowerPageView,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmTrialFunnelEyePowerPageView;
  };
