import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FunnelProduct } from '../../../../../effects/pages/funnel-product-selector.effect';
import { GTMPageEvent } from '../../../../enums';
import { GTMItemsInterface } from '../../../../interfaces';
import { gtmPageSlice } from '../../../../redux';

export type GTMTrialFunnelProductPageViewTrigger = (
  funnelProducts: FunnelProduct[],
  page: string,
  cart_id?: number
) => void;

export const useGTMTrialFunnelProductPageViewEffect =
  (): GTMTrialFunnelProductPageViewTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataTrialFunnelProductPageView, setTrialFunnelProductPageView] =
      useState<{
        ecommerce: {
          item_list_id: string;
          item_list_name: string;
          items: GTMItemsInterface[];
        };
        cart_id?: number;
      } | null>(null);

    const gtmTrialFunnelProductPageView = (
      funnelProducts: FunnelProduct[],
      page: string,
      cart_id?: number
    ) => {
      if (
        dataTrialFunnelProductPageView?.cart_id !== cart_id ||
        cart_id === undefined
      ) {
        const EcoItems: GTMItemsInterface[] = [] as any;

        for (let i = 0; i < funnelProducts.length; i++) {
          const items = {} as GTMItemsInterface;
          items.item_id = funnelProducts[i].id.toString();
          items.item_name = funnelProducts[i].title;
          items.affiliation = 'Waldo Store';
          items.index = +[i];
          items.item_brand = 'Waldo';
          items.item_variant = funnelProducts[i].handle;

          EcoItems.push(items);
        }

        setTrialFunnelProductPageView({
          ecommerce: {
            item_list_id: `funnel-${page}-selector`,
            item_list_name: `Funnel ${
              page[0].toUpperCase() + page.slice(1)
            } Selector`,
            items: EcoItems,
          },
          cart_id,
        });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);
        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_TRIAL_FUNNEL_PRODUCT_PAGE_VIEW,
            ...dataTrialFunnelProductPageView,
          })
        );
      }
    }, [
      dataTrialFunnelProductPageView,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmTrialFunnelProductPageView;
  };
