import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMTrialFunnelDoctorManualTrigger = (
  doctor: string,
  cart_id?: number
) => void;

export const useGTMTrialFunnelDoctorManualEffect =
  (): GTMTrialFunnelDoctorManualTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataTrialFunnelDoctorManual, setTrialFunnelDoctorManual] = useState<{
      doctor: string;
      cart_id?: number;
    } | null>(null);

    const gtmTrialFunnelDoctorManual = (doctor: string, cart_id?: number) => {
      if (dataTrialFunnelDoctorManual?.doctor && doctor) {
        setTrialFunnelDoctorManual({ doctor, cart_id });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_TRIAL_FUNNEL_DOCTOR_PAGE_MANUAL,
            ...dataTrialFunnelDoctorManual,
          })
        );
      }
    }, [
      dataTrialFunnelDoctorManual,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmTrialFunnelDoctorManual;
  };
