import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gtmPageSlice } from '../../../../redux';
import { Currency } from '@hiwaldo/sdk';
import { RevrollAddToCartProductsInterface } from '../../../../interfaces/page/revroll';
import { RevrollEvent } from '../../../../enums/revroll-event.enum';

export interface RevrollAddCartProduct {
  id: number;
  title: string;
  price: number;
  imageUrl: string;
  variantSku: string;
  quantity: number;
}

export type RevrollAddToCartTrigger = (
  funnelProducts: RevrollAddCartProduct[],
  cart_id?: number
) => void;

export const useRevrollAddToCartEffect = (): RevrollAddToCartTrigger => {
  const dispatch = useDispatch();

  const [hasTriggered, setTriggered] = useState(false);
  const [hasSent, setSent] = useState(false);
  const [currentCartId, setCurrentCartId] = useState(
    <{ cart_id: number } | null>null
  );

  const [dataRevrollAddToCart, setRevrollAddToCart] = useState<{
    ecommerce: {
      currencyCode: Currency;
      add: {
        products: RevrollAddToCartProductsInterface[];
      };
    };
  } | null>(null);

  const revrollAddToCart = (
    funnelProducts: RevrollAddCartProduct[],
    cart_id?: number
  ) => {
    if (currentCartId?.cart_id !== cart_id || cart_id === undefined) {
      const Products: RevrollAddToCartProductsInterface[] = [] as any;

      for (let i = 0; i < funnelProducts.length; i++) {
        const items = {} as RevrollAddToCartProductsInterface;
        items.name = funnelProducts[i].title;
        items.id = funnelProducts[i].id.toString();
        items.sku = funnelProducts[i].variantSku;
        items.variant_id = funnelProducts[i].imageUrl;
        items.price = funnelProducts[i].price || 0;
        items.brand = 'Waldo Store';
        items.category = '';
        items.image = funnelProducts[i].imageUrl;
        items.quantity = funnelProducts[i].quantity;

        Products.push(items);
      }

      setRevrollAddToCart({
        ecommerce: {
          currencyCode: Currency.USD,
          add: {
            products: Products,
          },
        },
      });
      if (cart_id) {
        setCurrentCartId({ cart_id });
      }
      setTriggered(true);
    }
  };

  useEffect(() => {
    if (hasTriggered && !hasSent) {
      setTriggered(false);
      setSent(true);
      dispatch(
        gtmPageSlice.actions.revrollUpdate({
          event: RevrollEvent.REVROLL_ADD_TO_CART,
          rr_cart_id: currentCartId?.cart_id,
          ...dataRevrollAddToCart,
        })
      );
    }
  }, [
    dataRevrollAddToCart,
    hasTriggered,
    setTriggered,
    hasSent,
    setSent,
    dispatch,
  ]);

  return revrollAddToCart;
};
