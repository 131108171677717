import { Cart } from '@hiwaldo/sdk/interfaces';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  calculateGtmCartItems,
  GTMItemsInterface,
  gtmPageSlice,
  GTMPageEvent,
} from '../../../../';

export type GTMCheckoutShippingSubmitTrigger = (
  cart: Cart,
  cart_id?: number,
  customer_id?: number
) => void;

export const useGTMCheckoutShippingSubmitEffect =
  (): GTMCheckoutShippingSubmitTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataCheckoutShippingSubmit, setCheckoutShippingSubmit] = useState<{
      ecommerce: {
        currency: string;
        value: number;
        coupon?: string;
        shipping_tier: string;
        items: GTMItemsInterface[];
      };
      cart_id?: number;
      customer_id?: number;
    } | null>(null);

    const gtmCheckoutShippingSubmit = (
      cart: Cart,
      cart_id?: number,
      customer_id?: number
    ) => {
      if (!dataCheckoutShippingSubmit?.customer_id && customer_id) {
        const items = calculateGtmCartItems(cart);

        const couponData = cart.discounts?.length
          ? {
              coupon: cart.discounts[0].discountCode,
            }
          : {};

        setCheckoutShippingSubmit({
          ecommerce: {
            currency: items.currency,
            value: items.amount,
            ...couponData,
            shipping_tier: 'Ground',
            items: items.items,
          },
          cart_id,
          customer_id,
        });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_CHECKOUT_ADD_SHIPPING_INFO,
            ...dataCheckoutShippingSubmit,
          })
        );
      }
    }, [
      dataCheckoutShippingSubmit,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmCheckoutShippingSubmit;
  };
