import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMTrialFunnelStartPageViewTrigger = () => void;

export const useGTMTrialFunnelStartPageViewEffect =
  (): GTMTrialFunnelStartPageViewTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const gtmTrialFunnelStartPageView = () => {
      setTriggered(true);
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_TRIAL_FUNNEL_START_PAGE_VIEW,
          })
        );
      }
    }, [hasTriggered, setTriggered, hasSent, setSent, dispatch]);

    return gtmTrialFunnelStartPageView;
  };
