import * as tracking from './tracking';
import * as revroll from './revroll';

export interface PageGoogleTagManagerEffect {
  gtmLogin: tracking.GTMLoginTrigger;
  gtmAccountView: tracking.GTMAccountViewTrigger;
  gtmCheckoutBegin: tracking.GTMCheckoutBeginTrigger;
  gtmCheckoutLogin: tracking.GTMCheckoutLoginTrigger;
  gtmCheckoutPaymentView: tracking.GTMCheckoutPaymentViewTrigger;
  gtmCheckoutRegisterSubmit: tracking.GTMCheckoutRegisterSubmitTrigger;
  gtmCheckoutRegisterView: tracking.GTMCheckoutRegisterViewTrigger;
  gtmCheckoutReviewNext: tracking.GTMCheckoutReviewNextTrigger;
  gtmCheckoutReviewView: tracking.GTMCheckoutReviewViewTrigger;
  gtmCheckoutShippingSubmit: tracking.GTMCheckoutShippingSubmitTrigger;
  gtmCheckoutShippingView: tracking.GTMCheckoutShippingViewTrigger;
  gtmProductBlueLightGlassesAdd: tracking.GTMProductBlueLightGlassesAddTrigger;
  gtmProductBlueLightGlassesView: tracking.GTMProductBlueLightGlassesViewTrigger;
  gtmProductGiftCardAdd: tracking.GTMProductGiftCardAddTrigger;
  gtmProductGiftCardView: tracking.GTMProductGiftCardViewTrigger;
  gtmProductHomeStart: tracking.GTMProductHomeStartTrigger;
  gtmProductHomeView: tracking.GTMProductHomeViewTrigger;
  gtmProductHydrationDropsAdd: tracking.GTMProductHydrationDropsAddTrigger;
  gtmProductHydrationDropsView: tracking.GTMProductHydrationDropsViewTrigger;
  gtmProductOriginalLensesAdd: tracking.GTMProductOriginalLensesAddTrigger;
  gtmProductOriginalLensesView: tracking.GTMProductOriginalLensesViewTrigger;
  gtmProductVitaminLensesAdd: tracking.GTMProductVitaminLensesAddTrigger;
  gtmProductVitaminLensesView: tracking.GTMProductVitaminLensesViewTrigger;
  gtmTrialFunnelDoctorManual: tracking.GTMTrialFunnelDoctorManualTrigger;
  gtmTrialFunnelDoctorPageView: tracking.GTMTrialFunnelDoctorPageViewTrigger;
  gtmTrialFunnelDoctorSelected: tracking.GTMTrialFunnelDoctorSelectedTrigger;
  gtmTrialFunnelEyePowerPageView: tracking.GTMTrialFunnelEyePowerPageViewTrigger;
  gtmTrialFunnelEyePowerSelected: tracking.GTMTrialFunnelEyePowerSelectedTrigger;
  gtmTrialFunnelFeaturePageView: tracking.GTMTrialFunnelFeaturePageViewTrigger;
  gtmTrialFunnelFeatureSelected: tracking.GTMTrialFunnelFeatureSelectedTrigger;
  gtmTrialFunnelProductPageView: tracking.GTMTrialFunnelProductPageViewTrigger;
  gtmTrialFunnelProductSelected: tracking.GTMTrialFunnelProductSelectedTrigger;
  gtmTrialFunnelStartPageNext: tracking.GTMTrialFunnelStartPageNextTrigger;
  gtmTrialFunnelStartPageView: tracking.GTMTrialFunnelStartPageViewTrigger;
  revrollTrialFunnelProductPageView: revroll.RevrollTrialFunnelProductPageViewTrigger;
  revrollBeginCheckout: revroll.RevrollBeginCheckoutTrigger;
  revrollAddToCart: revroll.RevrollAddToCartTrigger;
}

export const useGoogleTagManagerEffect = (): PageGoogleTagManagerEffect => {
  return {
    gtmLogin: tracking.useGTMLoginEffect(),
    gtmAccountView: tracking.useGTMAccountViewEffect(),
    gtmCheckoutBegin: tracking.useGTMCheckoutBeginEffect(),
    gtmCheckoutLogin: tracking.useGTMCheckoutLoginEffect(),
    gtmCheckoutPaymentView: tracking.useGTMCheckoutPaymentViewEffect(),
    gtmCheckoutRegisterSubmit: tracking.useGTMCheckoutRegisterSubmitEffect(),
    gtmCheckoutRegisterView: tracking.useGTMCheckoutRegisterViewEffect(),
    gtmCheckoutReviewNext: tracking.useGTMCheckoutReviewNextEffect(),
    gtmCheckoutReviewView: tracking.useGTMCheckoutReviewViewEffect(),
    gtmCheckoutShippingSubmit: tracking.useGTMCheckoutShippingSubmitEffect(),
    gtmCheckoutShippingView: tracking.useGTMCheckoutShippingViewEffect(),
    gtmProductBlueLightGlassesAdd:
      tracking.useGTMProductBlueLightGlassesAddEffect(),
    gtmProductBlueLightGlassesView:
      tracking.useGTMProductBlueLightGlassesViewEffect(),
    gtmProductGiftCardAdd: tracking.useGTMProductGiftCardAddEffect(),
    gtmProductGiftCardView: tracking.useGTMProductGiftCardViewEffect(),
    gtmProductHomeStart: tracking.useGTMProductHomeStartEffect(),
    gtmProductHomeView: tracking.useGTMProductHomeViewEffect(),
    gtmProductHydrationDropsAdd:
      tracking.useGTMProductHydrationDropsAddEffect(),
    gtmProductHydrationDropsView:
      tracking.useGTMProductHydrationDropsViewEffect(),
    gtmProductOriginalLensesAdd:
      tracking.useGTMProductOriginalLensesAddEffect(),
    gtmProductOriginalLensesView:
      tracking.useGTMProductOriginalLensesViewEffect(),
    gtmProductVitaminLensesAdd: tracking.useGTMProductVitaminLensesAddEffect(),
    gtmProductVitaminLensesView:
      tracking.useGTMProductVitaminLensesViewEffect(),
    gtmTrialFunnelDoctorManual: tracking.useGTMTrialFunnelDoctorManualEffect(),
    gtmTrialFunnelDoctorPageView:
      tracking.useGTMTrialFunnelDoctorPageViewEffect(),
    gtmTrialFunnelDoctorSelected:
      tracking.useGTMTrialFunnelDoctorSelectedEffect(),
    gtmTrialFunnelEyePowerPageView:
      tracking.useGTMTrialFunnelEyePowerPageViewEffect(),
    gtmTrialFunnelEyePowerSelected:
      tracking.useGTMTrialFunnelEyePowerSelectedEffect(),
    gtmTrialFunnelFeaturePageView:
      tracking.useGTMTrialFunnelFeaturePageViewEffect(),
    gtmTrialFunnelFeatureSelected:
      tracking.useGTMTrialFunnelFeatureSelectedEffect(),
    gtmTrialFunnelProductPageView:
      tracking.useGTMTrialFunnelProductPageViewEffect(),
    gtmTrialFunnelProductSelected:
      tracking.useGTMTrialFunnelProductSelectedEffect(),
    gtmTrialFunnelStartPageNext:
      tracking.useGTMTrialFunnelStartPageNextEffect(),
    gtmTrialFunnelStartPageView:
      tracking.useGTMTrialFunnelStartPageViewEffect(),
    revrollTrialFunnelProductPageView:
      revroll.useRevrollTrialFunnelProductPageViewEffect(),
    revrollBeginCheckout: revroll.useRevrollBeginCheckoutEffect(),
    revrollAddToCart: revroll.useRevrollAddToCartEffect(),
  };
};
