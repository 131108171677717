export const TrialVitaminBoostData = {
  id: 3,
  title: 'Vitamin Boost Trial',
  handle: 'vitamin-trial',
  plans: [
    {
      plan: {
        id: 16,
        planFamily: {
          title: 'Quarterly',
          handle: 'quarterly',
        },
        title: 'Vitamin',
        handle: 'vitamin-x-3',
        products: [
          {
            locales: [
              {
                quantity: 3,
                basePrice: 2250,
                actualPrice: 2100,
              },
              {
                quantity: 3,
                basePrice: 1600,
                actualPrice: 1360,
              },
              {
                quantity: 3,
                basePrice: 24500,
                actualPrice: 16575,
              },
              {
                quantity: 3,
                basePrice: 1900,
                actualPrice: 1615,
              },
            ],
            product: {
              title: 'Vitamin Lenses',
              handle: 'vitamin-lenses',
              description:
                'Help your eyes relax with our ultra-comfortable vitamin lenses.',
              primaryVariant: {
                assets: [
                  {
                    asset: {
                      path: '/images/products/vitamin-lens/2-Vitamin.png',
                    },
                  },
                ],
              },
            },
          },
        ],
        locales: [
          {
            basePrice: 13500,
            actualPrice: 12600,
            sku: 'quarterly-vitamin',
            region: 'US',
          },
          {
            basePrice: 9600,
            actualPrice: 8160,
            sku: 'quarterly-vitamin',
            region: 'UK',
          },
          {
            basePrice: 147000,
            actualPrice: 99450,
            sku: 'quarterly-vitamin',
            region: 'SE',
          },
          {
            basePrice: 11400,
            actualPrice: 9690,
            sku: 'quarterly-vitamin',
            region: 'EU',
          },
        ],
      },
    },
  ],
  products: [
    {
      quantity: 1,
      product: {
        id: 4,
        title: 'Vitamin Lens Trial',
        handle: 'trial-vitamin-lenses',
        description:
          'Help your eyes relax with our ultra-comfortable vitamin lenses.',
        primaryVariant: {
          assets: [
            {
              asset: {
                path: '/images/products/vitamin-lens/2-Vitamin.png',
              },
            },
          ],
        },
      },
    },
  ],
  bundles: [],
  locales: [
    {
      leadTime: 12,
      sku: 'vitamin-trial',
      region: 'US',
      pricing: [
        {
          actualPrice: 200,
          basePrice: 0,
        },
      ],
    },
    {
      leadTime: 12,
      sku: 'vitamin-trial',
      region: 'UK',
      pricing: [
        {
          actualPrice: 200,
          basePrice: 0,
        },
      ],
    },
    {
      leadTime: 12,
      sku: 'vitamin-trial',
      region: 'SE',
      pricing: [
        {
          actualPrice: 2000,
          basePrice: 0,
        },
      ],
    },
    {
      leadTime: 12,
      sku: 'vitamin-trial',
      region: 'EU',
      pricing: [
        {
          actualPrice: 200,
          basePrice: 0,
        },
      ],
    },
  ],
};
