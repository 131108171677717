import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMTrialFunnelFeatureSelectedTrigger = (
  featureType: string,
  order_id?: number
) => void;

export const useGTMTrialFunnelFeatureSelectedEffect =
  (): GTMTrialFunnelFeatureSelectedTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataTrialFunnelFeatureSelected, setTrialFunnelFeatureSelected] =
      useState<{
        featureType: string;
        order_id?: number;
      } | null>(null);

    const gtmTrialFunnelFeatureSelected = (
      featureType: string,
      order_id?: number
    ) => {
      if (!dataTrialFunnelFeatureSelected && featureType) {
        setTrialFunnelFeatureSelected({ featureType, order_id });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_TRIAL_FUNNEL_TRIAL_PAGE_SELECT,
            ...dataTrialFunnelFeatureSelected,
          })
        );
      }
    }, [
      dataTrialFunnelFeatureSelected,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmTrialFunnelFeatureSelected;
  };
