import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMTrialFunnelFeaturePageViewTrigger = (cart_id?: number) => void;

export const useGTMTrialFunnelFeaturePageViewEffect =
  (): GTMTrialFunnelFeaturePageViewTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataTrialFunnelFeaturePageView, setTrialFunnelFeaturePageView] =
      useState<{
        cart_id?: number;
      } | null>(null);

    const gtmTrialFunnelFeaturePageView = (cart_id?: number) => {
      if (!dataTrialFunnelFeaturePageView?.cart_id && cart_id) {
        setTrialFunnelFeaturePageView({ cart_id });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_TRIAL_FUNNEL_TRIAL_PAGE_VIEW,
            ...dataTrialFunnelFeaturePageView,
          })
        );
      }
    }, [
      dataTrialFunnelFeaturePageView,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmTrialFunnelFeaturePageView;
  };
