import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMTrialFunnelEyePowerSelectedTrigger = (
  leftEyePower: string,
  rightEyePower: string,
  order_id?: number
) => void;

export const useGTMTrialFunnelEyePowerSelectedEffect =
  (): GTMTrialFunnelEyePowerSelectedTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataTrialFunnelEyePowerSelected, setTrialFunnelEyePowerSelected] =
      useState<{
        leftEyePower: string;
        rightEyePower: string;
        order_id?: number;
      } | null>(null);

    const gtmTrialFunnelEyePowerSelected = (
      leftEyePower: string,
      rightEyePower: string,
      order_id?: number
    ) => {
      if (!dataTrialFunnelEyePowerSelected && leftEyePower && rightEyePower) {
        setTrialFunnelEyePowerSelected({
          leftEyePower,
          rightEyePower,
          order_id,
        });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_TRIAL_FUNNEL_EYE_POWER_PAGE_SELECT,
            ...dataTrialFunnelEyePowerSelected,
          })
        );
      }
    }, [
      dataTrialFunnelEyePowerSelected,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmTrialFunnelEyePowerSelected;
  };
