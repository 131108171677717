import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMCheckoutRegisterSubmitTrigger = (
  cart_id: number,
  customer_email: string
) => void;

export const useGTMCheckoutRegisterSubmitEffect =
  (): GTMCheckoutRegisterSubmitTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataCheckoutRegisterSubmit, setCheckoutRegisterSubmit] = useState<{
      cart_id: number;
      customer_email?: string;
    } | null>(null);

    const gtmCheckoutRegisterSubmit = (
      cart_id: number,
      customer_email: string
    ) => {
      if (!dataCheckoutRegisterSubmit?.cart_id && cart_id) {
        setCheckoutRegisterSubmit({ cart_id, customer_email });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_CHECKOUT_REGISTER_SUBMIT,
            ...dataCheckoutRegisterSubmit,
          })
        );
      }
    }, [
      dataCheckoutRegisterSubmit,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmCheckoutRegisterSubmit;
  };
