import { Cart } from '@hiwaldo/sdk/interfaces';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  calculateGtmCartItems,
  GTMItemsInterface,
  gtmPageSlice,
  GTMPageEvent,
} from '../../../../';

export type GTMCheckoutBeginTrigger = (cart: Cart, cart_id: number) => void;

export const useGTMCheckoutBeginEffect = (): GTMCheckoutBeginTrigger => {
  const dispatch = useDispatch();

  const [hasTriggered, setTriggered] = useState(false);
  const [hasSent, setSent] = useState(false);

  const [dataCheckoutBegin, setCheckoutBegin] = useState<{
    ecommerce: {
      currency: string;
      value: number;
      coupon?: string;
      items: GTMItemsInterface[];
    };
    cart_id: number;
  } | null>(null);

  const gtmCheckoutBegin = (cart: Cart, cart_id: number) => {
    if (dataCheckoutBegin?.cart_id !== cart_id || cart_id === undefined) {
      const items = calculateGtmCartItems(cart);

      const couponData = cart.discounts?.length
        ? {
            coupon: cart.discounts[0].discountCode,
          }
        : {};

      setCheckoutBegin({
        ecommerce: {
          currency: items.currency,
          value: items.amount,
          ...couponData,
          items: items.items,
        },
        cart_id,
      });

      setTriggered(true);
    }
  };

  useEffect(() => {
    if (hasTriggered && !hasSent) {
      setTriggered(false);
      setSent(true);

      dispatch(
        gtmPageSlice.actions.update({
          event: GTMPageEvent.WALDO_CHECKOUT_BEGIN,
          ...dataCheckoutBegin,
        })
      );
    }
  }, [
    dataCheckoutBegin,
    hasTriggered,
    setTriggered,
    hasSent,
    setSent,
    dispatch,
  ]);

  return gtmCheckoutBegin;
};
