import { Region } from '@hiwaldo/sdk';
import { Cart } from '@hiwaldo/sdk/interfaces';
import { currencyLabel } from '../../../utils';
import { GTMItemsInterface } from '../../interfaces';
import {
  TrialHydraBoostBundleData,
  TrialHydraBoostData,
  TrialVitaminBoostBundleData,
  TrialVitaminBoostData,
} from '../../../data';

interface CalculateGtmCartItemsData {
  items: GTMItemsInterface[];
  amount: number;
  currency: string;
}

export const calculateGtmCartItems = (
  cart: Cart
): CalculateGtmCartItemsData => {
  const amount = cart.grandTotalPrice / 100;
  const currency = currencyLabel(cart.region as unknown as Region, true);
  const items: GTMItemsInterface[] = [] as any;

  if ((cart.trials?.length || 0) > 0) {
    if (cart.trials[0].trial.title.includes('Bundle')) {
      let bundlePrice = 0;

      if (cart.trials[0].trial.title.includes('Hydra Boost')) {
        for (const x in TrialHydraBoostBundleData.locales) {
          if (TrialHydraBoostBundleData.locales[x].region === cart.region) {
            bundlePrice =
              TrialHydraBoostBundleData.locales[x].pricing[0].actualPrice / 100;
          }
        }
      } else if (cart.trials[0].trial.title.includes('Vitamin')) {
        for (const x in TrialVitaminBoostBundleData.locales) {
          if (TrialVitaminBoostBundleData.locales[x].region === cart.region) {
            bundlePrice =
              TrialVitaminBoostBundleData.locales[x].pricing[0].actualPrice /
              100;
          }
        }
      }

      items.push({
        item_id: cart.trials[0].trial.id.toString(),
        item_name: cart.trials[0].trial.title,
        sku:
          cart.trials[0].trial.locales.find(
            (locale) => locale.region === cart.region
          )?.sku || undefined,
        affiliation: 'Waldo Store',
        item_brand: 'Waldo',
        item_variant: cart.trials[0].trial.title.toLocaleLowerCase(),
        price: bundlePrice,
      });
    }

    if (!cart.trials[0].trial.title.includes('Bundle')) {
      let trialPrice;

      if (cart.trials[0].trial.title.includes('Hydra Boost')) {
        for (const x in TrialHydraBoostData.locales) {
          if (TrialHydraBoostData.locales[x].region === cart.region) {
            trialPrice =
              TrialHydraBoostData.locales[x].pricing[0].actualPrice / 100;
          }
        }
      } else if (cart.trials[0].trial.title.includes('Vitamin')) {
        for (const x in TrialVitaminBoostData.locales) {
          if (TrialVitaminBoostData.locales[x].region === cart.region) {
            trialPrice =
              TrialVitaminBoostData.locales[x].pricing[0].actualPrice / 100;
          }
        }
      }

      items.push({
        item_id: cart.trials[0].trial.id.toString(),
        item_name: cart.trials[0].trial.title,
        sku:
          cart.trials[0].trial.locales.find(
            (locale) => locale.region === cart.region
          )?.sku || undefined,
        affiliation: 'Waldo Store',
        item_brand: 'Waldo',
        item_variant: cart.trials[0].trial.title.toLocaleLowerCase(),
        price: trialPrice,
      });
    }
  }

  if (cart.productVariants) {
    for (let i = 0; i < cart.productVariants.length; i++) {
      items.push({
        item_id: cart.productVariants[i].productVariant.id.toString(),
        item_name: cart.productVariants[i].productVariant.title,
        sku:
          cart.productVariants[i].productVariant.locales.find(
            (locale) => locale.region === (cart.region as unknown as Region)
          )?.sku || undefined,
        affiliation: 'Waldo Store',
        item_brand: 'Waldo',
        item_variant: cart.productVariants[i].productVariant.handle,
        quantity: cart.productVariants[i].quantity,
        price: cart.productVariants[i].itemPrice / 100,
      });
    }
  }

  return { items, amount, currency };
};
