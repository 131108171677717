import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gtmPageSlice } from '../../../../redux';
import { Currency } from '@hiwaldo/sdk';
import { RevrollViewProductsInterface } from '../../../../interfaces/page/revroll';
import { RevrollEvent } from '../../../../enums/revroll-event.enum';

export interface RevrollTrialFunnelProduct {
  id: number;
  title: string;
  price: number;
  imageUrl: string;
  variantSku: string;
  quantity: number;
}

export type RevrollTrialFunnelProductPageViewTrigger = (
  funnelProducts: RevrollTrialFunnelProduct[],
  cart_id?: number
) => void;

export const useRevrollTrialFunnelProductPageViewEffect =
  (): RevrollTrialFunnelProductPageViewTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);
    const [currentCartId, setCurrentCartId] = useState(
      <{ cart_id: number } | null>null
    );

    const [dataTrialFunnelProductPageView, setTrialFunnelProductPageView] =
      useState<{
        ecommerce: {
          currencyCode: Currency;
          detail: {
            products: RevrollViewProductsInterface[];
          };
        };
      } | null>(null);

    const revrollTrialFunnelProductPageView = (
      funnelProducts: RevrollTrialFunnelProduct[],
      cart_id?: number
    ) => {
      if (currentCartId?.cart_id !== cart_id || cart_id === undefined) {
        const products: RevrollViewProductsInterface[] = funnelProducts.map(
          (funnelProduct) => {
            return {
              name: funnelProduct.title,
              id: funnelProduct.id.toString(),
              variantSku: funnelProduct.variantSku,
              image: funnelProduct.imageUrl,
              shopifyId: '',
              brand: 'Waldo Store',
              price: funnelProduct.price,
              variant: funnelProduct.title,
            };
          }
        );

        setTrialFunnelProductPageView({
          ecommerce: {
            currencyCode: Currency.USD,
            detail: { products },
          },
        });
        if (cart_id) {
          setCurrentCartId({ cart_id });
        }
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);
        dispatch(
          gtmPageSlice.actions.revrollUpdate({
            event: RevrollEvent.REVROLL_VIEW_ITEM,
            rr_cart_id: currentCartId?.cart_id,
            ...dataTrialFunnelProductPageView,
          })
        );
      }
    }, [
      dataTrialFunnelProductPageView,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return revrollTrialFunnelProductPageView;
  };
