import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMProductGiftCardAddTrigger = (order_id: number) => void;

export const useGTMProductGiftCardAddEffect =
  (): GTMProductGiftCardAddTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataProductGiftCardAdd, setProductGiftCardAdd] = useState<{
      order_id: number;
    } | null>(null);

    const gtmProductGiftCardAdd = (order_id: number) => {
      if (dataProductGiftCardAdd?.order_id !== order_id) {
        setProductGiftCardAdd({ order_id });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_PRODUCT_GIFT_CARD_ADD,
            ...dataProductGiftCardAdd,
          })
        );
      }
    }, [
      dataProductGiftCardAdd,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmProductGiftCardAdd;
  };
