import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMProductBlueLightGlassesAddTrigger = (cart_id: number) => void;

export const useGTMProductBlueLightGlassesAddEffect =
  (): GTMProductBlueLightGlassesAddTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataProductBlueLightGlassesAdd, setProductBlueLightGlassesAdd] =
      useState<{
        cart_id: number;
      } | null>(null);

    const gtmProductBlueLightGlassesAdd = (cart_id: number) => {
      if (dataProductBlueLightGlassesAdd?.cart_id !== cart_id) {
        setProductBlueLightGlassesAdd({ cart_id });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_PRODUCT_BLUE_LIGHT_GLASSES_ADD,
            ...dataProductBlueLightGlassesAdd,
          })
        );
      }
    }, [
      dataProductBlueLightGlassesAdd,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmProductBlueLightGlassesAdd;
  };
