import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FunnelProduct } from '../../../../../effects/pages/funnel-product-selector.effect';
import { GTMItemsInterface, gtmPageSlice, GTMPageEvent } from '../../../../';

export type GTMTrialFunnelProductSelectedTrigger = (
  funnelProduct: FunnelProduct,
  page: string,
  cart_id?: number
) => void;

export const useGTMTrialFunnelProductSelectedEffect =
  (): GTMTrialFunnelProductSelectedTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataTrialFunnelProductSelected, setTrialFunnelProductSelected] =
      useState<{
        ecommerce: {
          item_list_id: string;
          item_list_name: string;
          items: GTMItemsInterface;
        };
        cart_id?: number;
      } | null>(null);

    const gtmTrialFunnelProductSelected = (
      funnelProduct: FunnelProduct,
      page: string,
      cart_id?: number
    ) => {
      let EcoItems = {} as GTMItemsInterface;
      if (
        dataTrialFunnelProductSelected?.cart_id !== cart_id ||
        cart_id === undefined
      ) {
        const data = {
          item_id: funnelProduct.id.toString(),
          item_name: funnelProduct.title,
          affiliation: 'Waldo Store',
          item_brand: 'Waldo',
          item_variant: funnelProduct.handle,
        };

        EcoItems = data;
      }

      setTrialFunnelProductSelected({
        ecommerce: {
          item_list_id: `funnel-${page}-selector selected`,
          item_list_name: `Funnel ${
            page[0].toUpperCase() + page.slice(1)
          } Selector`,
          items: EcoItems,
        },
        cart_id,
      });
      setTriggered(true);
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_TRIAL_FUNNEL_PRODUCT_PAGE_SELECT,
            ...dataTrialFunnelProductSelected,
          })
        );
      }
    }, [
      dataTrialFunnelProductSelected,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmTrialFunnelProductSelected;
  };
