export const TrialHydraBoostData = {
  id: 1,
  title: 'Lens Trial',
  handle: 'hydra-boost-trial',
  plans: [
    {
      plan: {
        id: 15,
        planFamily: {
          title: 'Quarterly',
          handle: 'quarterly',
        },
        title: 'Hydra Boost',
        handle: 'hydra-boost-x-3',
        products: [
          {
            locales: [
              {
                quantity: 3,
                basePrice: 1950,
                actualPrice: 2100,
              },
              {
                quantity: 3,
                basePrice: 1200,
                actualPrice: 1250,
              },
              {
                quantity: 3,
                basePrice: 14500,
                actualPrice: 12325,
              },
              {
                quantity: 3,
                basePrice: 1600,
                actualPrice: 1360,
              },
            ],
            product: {
              title: 'Hydra Boost Daily Lenses',
              handle: 'daily-lenses',
              description:
                'Designed for optimum comfort and moisture. Each lens is made from ultra-breathable Etafilcon A with a high water content of 58%.',
              primaryVariant: {
                assets: [
                  {
                    asset: {
                      path: '/images/products/original-lens/subscription-lens-blister.png',
                    },
                  },
                ],
              },
            },
          },
        ],
        locales: [
          {
            basePrice: 11700,
            actualPrice: 12000,
            sku: 'quarterly-hydra-boost',
            region: 'US',
          },
          {
            basePrice: 7200,
            actualPrice: 7000,
            sku: 'quarterly-hydra-boost',
            region: 'UK',
          },
          {
            basePrice: 87000,
            actualPrice: 88000,
            sku: 'quarterly-hydra-boost',
            region: 'SE',
          },
          {
            basePrice: 9600,
            actualPrice: 9400,
            sku: 'quarterly-hydra-boost',
            region: 'EU',
          },
        ],
      },
    },
  ],
  products: [
    {
      quantity: 1,
      product: {
        id: 2,
        title: 'Hydra Boost Daily Lenses',
        handle: 'trial-daily-lenses',
        description:
          'Designed for optimum comfort and moisture. Each lens is made from ultra-breathable Etafilcon A with a high water content of 58%.',
        primaryVariant: {
          assets: [
            {
              asset: {
                path: '/images/products/original-lens/subscription-lens-blister.png',
              },
            },
          ],
        },
      },
    },
  ],
  bundles: [],
  locales: [
    {
      leadTime: 12,
      sku: 'hydra-boost-trial',
      region: 'US',
      pricing: [
        {
          actualPrice: 0,
          basePrice: 0,
        },
      ],
    },
    {
      leadTime: 12,
      sku: 'hydra-boost-trial',
      region: 'UK',
      pricing: [
        {
          actualPrice: 0,
          basePrice: 0,
        },
      ],
    },
    {
      leadTime: 12,
      sku: 'hydra-boost-trial',
      region: 'SE',
      pricing: [
        {
          actualPrice: 0,
          basePrice: 0,
        },
      ],
    },
    {
      leadTime: 12,
      sku: 'hydra-boost-trial',
      region: 'EU',
      pricing: [
        {
          actualPrice: 0,
          basePrice: 0,
        },
      ],
    },
  ],
};
