import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GTMPageEvent } from '../../../../enums';
import { gtmPageSlice } from '../../../../redux';

export type GTMTrialFunnelDoctorSelectedTrigger = (
  doctor: string,
  cart_id?: number
) => void;

export const useGTMTrialFunnelDoctorSelectedEffect =
  (): GTMTrialFunnelDoctorSelectedTrigger => {
    const dispatch = useDispatch();

    const [hasTriggered, setTriggered] = useState(false);
    const [hasSent, setSent] = useState(false);

    const [dataTrialFunnelDoctorSelected, setTrialFunnelDoctorSelected] =
      useState<{
        doctor: string;
        cart_id?: number;
      } | null>(null);

    const gtmTrialFunnelDoctorSelected = (doctor: string, cart_id?: number) => {
      if (dataTrialFunnelDoctorSelected?.doctor && doctor) {
        setTrialFunnelDoctorSelected({ doctor, cart_id });
        setTriggered(true);
      }
    };

    useEffect(() => {
      if (hasTriggered && !hasSent) {
        setTriggered(false);
        setSent(true);

        dispatch(
          gtmPageSlice.actions.update({
            event: GTMPageEvent.WALDO_TRIAL_FUNNEL_DOCTOR_PAGE_SELECT,
            ...dataTrialFunnelDoctorSelected,
          })
        );
      }
    }, [
      dataTrialFunnelDoctorSelected,
      hasTriggered,
      setTriggered,
      hasSent,
      setSent,
      dispatch,
    ]);

    return gtmTrialFunnelDoctorSelected;
  };
